import { configureStore } from '@reduxjs/toolkit';
import websocketReducer from '../actions/slices/websocketSlice';
import headerSlice from '../features/common/headerSlice';
import modalSlice from '../features/common/modalSlice';
import rightDrawerSlice from '../features/common/rightDrawerSlice';
import { dashboardReducer } from '../actions/slices/dashboard';
import { notificationsReducer } from '../actions/slices/notifications';

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  dashboard: dashboardReducer,
  notifications: notificationsReducer,
  websocket: websocketReducer,
};

const store = configureStore({
  reducer: combinedReducer,
});

export default store;
