import { connect, disconnect, receiveMessage } from '../actions/slices/websocketSlice';
import store from '../app/store';

let ws = null;
let reconnectTimeout = null;

export const initializeWebSocket = () => {
    // Проверяем, нет ли уже активного соединения
    if (ws && (ws.readyState === WebSocket.CONNECTING || ws.readyState === WebSocket.OPEN)) {
        console.log('WebSocket соединение уже существует');
        return;
    }

    // Очищаем предыдущий таймаут переподключения, если он есть
    if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
    }

    ws = new WebSocket('wss://webtransfer.app/node');

    ws.onopen = () => {
        store.dispatch(connect());
        console.log('WebSocket соединение установлено');
    };

    ws.onclose = () => {
        store.dispatch(disconnect());
        // Попытка переподключения через 5 секунд
        reconnectTimeout = setTimeout(() => {
            if (!ws || ws.readyState === WebSocket.CLOSED) {
                initializeWebSocket();
            }
        }, 5000);
    };

    ws.onmessage = (event) => {
        try {
            const data = JSON.parse(event.data);
            store.dispatch(receiveMessage(data));
        } catch (error) {
            console.error('Ошибка при обработке сообщения WebSocket:', error);
        }
    };

    ws.onerror = (error) => {
        console.error('WebSocket ошибка:', error);
    };
};

export const closeWebSocket = () => {
    if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
    }
    
    if (ws) {
        ws.close();
        ws = null;
    }
};