import apiClient from './axiosConfig';

export const getLanguageDistribution = async () => {
  try {
    const response = await apiClient.get('/node/api/admin/dashboard/get_language_distribution');
    return response.data.languages;
  } catch (error) {
    console.error('Ошибка при получении распределения языков:', error);
    throw error;
  }
};

export const sendOneMessage = async (userId, messageText) => {
  try {
    const response = await apiClient.post('/node/api/admin/telegram/send_message/one_user', { userId, messageText });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при отправке сообщения';
  }
};

export const fetchDashboardApi = async () => {
  try {
    const response = await apiClient.post('/node/api/admin/dashboard');
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при отправке сообщения';
  }
};

export const checkUserInfo = async (user_id) => {
  try {
    const response = await apiClient.post('/node/api/admin/get_user_info', (user_id));
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при отправке сообщения';
  }
};

export const updateUserStatus = async (userId, bannedStatus) => {
  try {
    const response = await apiClient.put('/node/api/admin/get_user_info/update_status', {
      user_id: userId,
      banned: bannedStatus
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при обновлении статуса пользователя';
  }
};

export const closeLoansBulk = async (loanIds, currency, hash) => {
  try {
    const response = await apiClient.post('/node/api/admin/loans/close', {
      loanIds,
      currency,
      hash
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при обновлении статуса займа';
  }
};

export const withdrawalSuccess = async (withdrawal_ids, hash) => {
  try {
    const response = await apiClient.post('/node/api/admin/withdrawal/success', {
      withdrawal_ids,
      hash
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при обновлении статуса вывода';
  }
};

export const withdrawalRejected = async (withdrawal_id) => {
  try {
    const response = await apiClient.post('/node/api/admin/withdrawal/rejected', {
      withdrawal_id
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при обновлении статуса вывода';
  }
};

export const createLoanInTelegram = async (loanData) => {
  try {
    const response = await apiClient.post('/node/api/admin/telegram/send_message/loan_in_group', loanData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при создании займа';
  }
};

export const fetchLendingRequests = async (filters) => {
  const response = await apiClient.get(`/node/api/admin/p2p/lending_requests`, {
    params: filters,
  });
  return response.data;
};

export const fetchBorrowingRequests = async (filters) => {
  const response = await apiClient.get(`/node/api/admin/p2p/borrowing_requests`, {
    params: filters,
  });
  return response.data;
};

export const createWithdrawalInTelegram = async (withdrawalData) => {
  try {
    const response = await apiClient.post('/node/api/admin/telegram/send_message/withdrawal_in_group', withdrawalData);
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при создании займа';
  }
};

export const fetchNotificationsApi = async () => {
  try {
    const response = await apiClient.get('/node/api/admin/notifications');
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при создании займа';
  }
};

export const soldLoan = async (loanId, amount, hash) => {
  try {
    const response = await apiClient.post('/node/api/admin/loans/sale', { loanId, amount, hash });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при продаже займа';
  }
}

export const getPartnersList = async () => {
  try {
    const response = await apiClient.get('/node/api/admin/partners');
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при запросе списка';
  }
};

export const partnersWithdrawals = async (hash, partnerIds) => {
  try {
    const response = await apiClient.post('/node/api/admin/withdrawal/partners', { hash, partnerIds });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при обновлении статуса вывода';
  }
}

export const getOneHourActionList = async () => {
  try {
    const response = await apiClient.get('/node/api/admin/actions/oneHour');
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при запросе списка';
  }
};

export const closePayouts = async (currency) => {
  try {
    const response = await apiClient.put('/node/api/admin/actions/closePayouts', { currency });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при закрытии займов';
  }
}

// Функции для работы с коэффициентами
export const fetchDailyMultipliers = async () => {
  try {
    const response = await apiClient.get('/node/api/admin/multipliers/daily-multipliers');
    return response.data;
  } catch (error) {
    console.error('Ошибка при загрузке коэффициентов:', error);
    throw error;
  }
};

export const saveDailyMultiplier = async (date, multiplier) => {
  try {
    const response = await apiClient.post('/node/api/admin/multipliers/daily-multipliers', {
      date,
      multiplier
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при сохранении коэффициента:', error);
    throw error;
  }
};

export const deleteDailyMultiplier = async (date) => {
  try {
    const response = await apiClient.delete(`/node/api/admin/multipliers/daily-multipliers/${date}`);
    return response.data;
  } catch (error) {
    console.error('Ошибка при удалении коэффициента:', error);
    throw error;
  }
};

export const getContestList = async () => {
  try {
    const response = await apiClient.get('/api/contest.php');
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при запросе списка победителей';
  }
};

export const getInvestContestList = async () => {
  try {
    const response = await apiClient.get('/api/invest-contest.php');
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при запросе списка победителей';
  }
};

export const updateInvestContestPayments = async (hash, winnersData) => {
  try {
    const response = await apiClient.post('/api/invest-contest_update.php', {
      transaction_hash: hash,
      winners_data: winnersData
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при обновлении статуса выплат';
  }
};

export const updateContestPayments = async (hash, payBonuses = false) => {
  try {
    const response = await apiClient.post('/api/contest_update.php', {
      transaction_hash: hash,
      paid_at: new Date().toISOString(),
      pay_bonuses: payBonuses
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при обновлении статуса выплат';
  }
};

export const getInvestContestJuniors = async (winnerId) => {
  try {
    const response = await apiClient.get(`/api/invest-contest-juniors.php?winner_id=${winnerId}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при запросе списка младших рефералов';
  }
};

export const getPostReports = async (resolved = null) => {
  try {
    const url = resolved !== null ? `/api/post-reports.php?resolved=${resolved}` : '/api/post-reports.php';
    const response = await apiClient.get(url);
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при запросе списка жалоб';
  }
};

export const updatePostReport = async (reportId, resolved, deletePost = false) => {
  try {
    const response = await apiClient.post('/api/post-reports-update.php', {
      report_id: reportId,
      resolved: resolved,
      delete_post: deletePost ? 1 : 0
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Ошибка при обновлении статуса жалобы';
  }
};

export const fetchUserTasksForModeration = async (filters = {}) => {
  try {
    let url = '/api/user_tasks.php';

    // Добавление параметров фильтрации в URL
    if (filters.status) {
      url += `?status=${filters.status}`;
    }

    const response = await apiClient.get(url);
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении заданий для модерации:', error);
    throw new Error('Не удалось загрузить задания для модерации');
  }
};

export const moderateUserTask = async (taskId, action, reason = '', updatedLink = '') => {
  try {
    const payload = {
      task_id: taskId,
      action,
      reason
    };

    payload.updated_link = updatedLink;
    const response = await apiClient.post('/api/user_tasks.php', payload);
    return response.data;
  } catch (error) {
    console.error('Ошибка при модерации задания:', error);
    throw new Error('Не удалось выполнить модерацию задания');
  }
};

export const blacklistContestUser = async (userId) => {
  try {
    const response = await apiClient.post('/api/invest-contest.php', {
      action: 'blacklist',
      user_id: userId
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || { status: 'error', message: 'Failed to blacklist user' };
  }
};

// Новые функции для работы с системой безопасности

// Получение данных для дашборда безопасности
export const getSecurityDashboard = async () => {
  try {
    const response = await apiClient.get('/api/security.php');
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении данных безопасности:', error);
    throw error;
  }
};

// Получение статистики по безопасности
export const getSecurityStats = async () => {
  try {
    const response = await apiClient.post('/api/security.php', {
      action: 'get_stats'
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении статистики безопасности:', error);
    throw error;
  }
};

// Получение списка заблокированных пользователей
export const getBannedUsers = async (limit = 50, offset = 0, searchTerm = '') => {
  try {
    const response = await apiClient.get('/api/security.php', {
      params: {
        action: 'banned_users',
        limit,
        offset,
        search: searchTerm
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении списка заблокированных пользователей:', error);
    throw error;
  }
};

// Получение списка заблокированных IP
export const getBannedIPs = async (limit = 50, offset = 0, searchTerm = '') => {
  try {
    const response = await apiClient.get('/api/security.php', {
      params: {
        action: 'banned_ips',
        limit,
        offset,
        search: searchTerm
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении списка заблокированных IP:', error);
    throw error;
  }
};

// Получение списка инцидентов безопасности
export const getSecurityIncidents = async (limit = 50, offset = 0, searchTerm = '', severity = '', userId = null) => {
  try {
    const response = await apiClient.get('/api/security.php', {
      params: {
        action: 'incidents',
        limit,
        offset,
        search: searchTerm,
        severity,
        user_id: userId
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении списка инцидентов безопасности:', error);
    throw error;
  }
};

// Разблокировка пользователя
export const unbanUser = async (userId) => {
  try {
    if (!userId) {
      throw new Error('Не указан ID пользователя');
    }

    console.log(`API: Отправка запроса на разблокировку пользователя ${userId}`);
    const response = await apiClient.post('/api/security.php', {
      action: 'unban_user',
      user_id: userId
    });

    console.log('API: Ответ на разблокировку:', response.data);

    if (!response.data) {
      throw new Error('Пустой ответ от сервера');
    }

    return response.data;
  } catch (error) {
    console.error('API: Ошибка при разблокировке пользователя:', error);
    // Преобразуем ошибку в понятный формат
    return {
      status: 'error',
      message: error.response?.data?.message || error.message || 'Ошибка при разблокировке пользователя'
    };
  }
};

// Разблокировка IP-адреса
export const unbanIP = async (ipId) => {
  try {
    const response = await apiClient.post('/api/security.php', {
      action: 'unban_ip',
      ip_id: ipId
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при разблокировке IP-адреса:', error);
    throw error;
  }
};

// Блокировка пользователя
export const banUser = async (userId, reason) => {
  try {
    const response = await apiClient.post('/api/security.php', {
      action: 'ban_user',
      user_id: userId,
      reason
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при блокировке пользователя:', error);
    throw error;
  }
};

// Создание инцидента безопасности
export const createSecurityIncident = async (ipAddress, reason, severity, userId = null, userAgent = null, additionalInfo = null) => {
  try {
    const response = await apiClient.post('/api/security.php', {
      action: 'create_incident',
      ip_address: ipAddress,
      reason,
      severity,
      user_id: userId,
      user_agent: userAgent,
      additional_info: additionalInfo
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при создании инцидента безопасности:', error);
    throw error;
  }
};

// Блокировка IP-адреса
export const banIPAddress = async (ipAddress, reason, expiresDays = null, incidentId = null) => {
  try {
    const response = await apiClient.post('/api/security.php', {
      action: 'ban_ip',
      ip_address: ipAddress,
      reason,
      expires_days: expiresDays,
      incident_id: incidentId
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при блокировке IP-адреса:', error);
    throw error;
  }
};
