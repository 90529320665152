import { createSlice } from '@reduxjs/toolkit';

const websocketSlice = createSlice({
  name: 'websocket',
  initialState: {
    isConnected: false,
    messages: [],
    activeUsers: 0,
  },
  reducers: {
    connect: (state) => {
      state.isConnected = true;
    },
    disconnect: (state) => {
      state.isConnected = false;
    },
    receiveMessage: (state, action) => {
      state.messages.push(action.payload);
      if (action.payload.type === 'activeUsers') {
        state.activeUsers = action.payload.ping;
      }
    },
  },
});

export const { connect, disconnect, receiveMessage } = websocketSlice.actions;
export default websocketSlice.reducer;
